import { useState } from "react";
import { faQrcode, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactSearchAutocomplete } from "react-search-autocomplete";

function SearchBar({ onSearch, qrcode, onClickGenerateQRCode }) {
  const [address, setAddress] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [options, setOptions] = useState("Location");

  const fetchSuggestions = async (value) => {
    const apiUrl = `https://api-adresse.data.gouv.fr/search/?q=${encodeURIComponent(
      value
    )}&autocomplete=1&limit=5`;

    try {
      const response = await fetch(apiUrl);
      const data = await response.json();

      // Assurez-vous que la réponse contient des suggestions
      if (data.features) {
        console.log(JSON.stringify(data.features[0].geometry));
        const suggestions = data.features.map((feature, index) => ({
          name: feature.properties.label, // Utilisez la propriété correcte de votre suggestion
          id: index,
          coordinates: feature.geometry.coordinates,
        }));
        setSuggestions(suggestions);
      } else {
        setSuggestions([]);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des suggestions :", error);
    }
  };

  const handleSearch = (coordinates) => {
    onSearch(coordinates, options);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      if (address) handleOnSearch(address);
    }
  };
  const renderSuggestion = (suggestion) => (
    <div className="relative">
      <div key={suggestion.id} className="py-2 border-b">
        <span>
          {suggestion.name}- {suggestion.id}
        </span>
      </div>
    </div>
  );
  const handleOnSearch = async (string) => {
    await fetchSuggestions(string);
  };
  const inputProps = {
    placeholder: "Entrez une adresse",
    value: address,
    onChange: (event, { newValue }) => setAddress(newValue),
    onKeyDown: handleKeyDown,
    className:
      "py-[2%] px-8 bg-gray-200 rounded-full focus:outline-none focus:bg-white mr-4",
  };

  const handleOnSelect = (item) => {
    setAddress(item.name);
    handleSearch(item.coordinates);
  };
  const handleOnClear = () => {
    setAddress("");
  };

  return (
    <>
      <div className=" px-6 left-1 py-[2%] bg-gray-200 w-full shadow-lg rounded-lg ">
        <div className="flex items-center">
          <div className="relative w-full">
            {/* <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={(suggestion) => suggestion.name}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
                
              />  */}
            <ReactSearchAutocomplete
              items={suggestions}
              onSearch={handleOnSearch}
              //onHover={handleOnHover}
              onSelect={handleOnSelect}
              //onFocus={handleOnFocus}
              onClear={handleOnClear}
              styling={{ zIndex: 4 }} // To display it on top of the search box below
              autoFocus
            />
          </div>
          {/* <button onClick={handleSearch}>
            <div className="px-1">
              <FontAwesomeIcon icon={faSearch} />
            </div>
          </button> */}
        </div>
        {qrcode == "true" ? (
          <button
            onClick={() => onClickGenerateQRCode()}
            title="Générer le qrCode"
            style={{ display: qrcode == "true" ? "flex" : "none" }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <FontAwesomeIcon
                icon={faQrcode}
                style={{ marginRight: "8px" }}
              ></FontAwesomeIcon>
              <p>Générer le QRCode</p>
            </div>
          </button>
        ) : (
          <div className="radio-buttons" style={{ display: "block" }}>
            <label style={{ marginRight: "5px" }}>
              <input
                type="radio"
                name="radio-button"
                value="Location"
                defaultChecked={true}
                onChange={(e) => setOptions(e.target.value)}
                style={{ marginRight: "2px" }}
              />
              Location
            </label>
            <label style={{ marginRight: "5px" }}>
              <input
                type="radio"
                name="radio-button"
                value="Dechetterie"
                defaultChecked={false}
                onChange={(e) => setOptions(e.target.value)}
                style={{ marginRight: "2px" }}
              />
              Déchetteries
            </label>
            <label style={{ marginRight: "5px" }}>
              <input
                type="radio"
                name="radio-button"
                value="Carrières"
                defaultChecked={false}
                onChange={(e) => setOptions(e.target.value)}
                style={{ marginRight: "2px" }}
              />
              Carrières
            </label>
          </div>
        )}
      </div>
    </>
  );
}

export default SearchBar;
