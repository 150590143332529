import { useNavigate } from "react-router-dom";

const ChooseNavigation = () => {
  const navigation = useNavigate();
  return (
    <div className="flex flex-col md:flex-row justify-center items-center w-full md:h-dvh px-8">
      <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0 md:space-x-8 w-full max-w-screen-xl mx-auto">
        <div className="card bg-base-100 image-full h-72 w-64 shadow-xl">
          <figure>
            <img
              src="img/Maps.png"
              alt="Location"
              className="object-cover h-full w-full"
            />
          </figure>
          <div className="card-body">
            <h2 className="card-title">Location / déchetteries / carrières</h2>
            <p
              title="Permet d’obtenir les emplacements les plus proches."
              className=""
            >
              Permet d’obtenir les emplacements les plus proches.
            </p>
            <div className="card-actions justify-end">
              <button
                onClick={() => navigation("/maps")}
                className="btn btn-primary"
              >
                J'y vais !
              </button>
            </div>
          </div>
        </div>
        <div className="divider md:divider-horizontal my-4 md:my-0 mx-auto">
          OU
        </div>
        <div className="card bg-base-100 image-full h-72 w-64 shadow-xl">
          <figure>
            <img
              src="img/qrcode.jfif"
              alt="QRCODE"
              className="object-cover h-full w-full"
            />
          </figure>
          <div className="card-body">
            <h2 className="card-title">QRCODE</h2>
            <p>Génération de QRCODE</p>
            <div className="card-actions justify-end">
              <button
                onClick={() => navigation("/qrcode")}
                className="btn btn-primary"
              >
                J'y vais !
              </button>
            </div>
          </div>
        </div>
        <div className="divider md:divider-horizontal my-4 md:my-0 mx-auto">
          OU
        </div>
        <div className="card bg-base-100 image-full h-72 w-64 shadow-xl">
          <figure>
            <img
              src="img/attachement.png"
              alt="Shoes"
              className="object-cover h-full w-full"
            />
          </figure>
          <div className="card-body">
            <h2 className="card-title">Attachements</h2>
            <p>Permet d'éffectuer un attachement</p>
            <div className="card-actions justify-end">
              <button
                onClick={() => navigation("/attachement")}
                className="btn btn-primary"
              >
                J'y vais !
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseNavigation;
