import { createSlice } from "@reduxjs/toolkit";
import { registerUser, userLogin, deleteUser,userLogout,updatePassword } from "./authActions";

const initialState = {
  loading: false,
  userInfo: null,
  userToken: localStorage.getItem("userToken") || null,
  error: null,
  success: false,
};


const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials: (state, { payload }) => {
      state.userInfo = payload;
    },
  },
  extraReducers: {
    [userLogin.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [userLogin.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userInfo = JSON.parse(payload).user;
      state.userToken = JSON.parse(payload).token;
    },
    [userLogin.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // register user
    [registerUser.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [registerUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true; // registration successful
    },
    [registerUser.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // delete user
    [deleteUser.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteUser.fulfilled]: (state) => {
      state.loading = false;
    },
    [deleteUser.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [userLogout.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [userLogout.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userInfo = null;
      state.userToken = null;
      state.error = null;
    },
    [userLogout.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    //UPDATE user Password
    [updatePassword.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updatePassword.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true; // password update successful
    },
    [updatePassword.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export const { setCredentials } = authSlice.actions;
export default authSlice.reducer;
