import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import './Draglist.css'

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

// const grid = 6;

// const getItemStyle = (isDragging, draggableStyle) => ({
//   // some basic styles to make the orderList look a bit nicer
//   userSelect: "none",
//   padding: grid * 2,
//   margin: `0 0 ${grid}px 0`,

//   // change background colour if dragging
//   background: isDragging ? "lightgreen" : "grey",

//   // styles we need to apply on draggables
//   ...draggableStyle,
// });

// const getListStyle = (isDraggingOver) => ({
//   background: isDraggingOver ? "lightblue" : "lightgrey",
//   padding: grid,
//   width: 250,
// });

const DragToReorderList = ({ orderList, handleOrderChange }) => {


  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const itemsReorder = reorder(
      orderList,
      result.source.index,
      result.destination.index
    );
      const itemsWithUpdatedPos = itemsReorder.map((item, index) => ({
        ...item,
        pos: index + 1, // assuming pos starts from 1
      }));

     handleOrderChange(itemsWithUpdatedPos);
   // setItems(itemsReorder);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className={`droppable-container ${
              snapshot.isDraggingOver ? "dragging-over" : ""
            }`}
          >
            {orderList
              .sort(
                (a, b) =>
                  (a.pos || Number.MAX_SAFE_INTEGER) -
                  (b.pos || Number.MAX_SAFE_INTEGER)
              )
              .map((item, index) => (
                <Draggable
                  key={item.id}
                  draggableId={item.id.toString()}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={`draggable-item ${
                        snapshot.isDragging ? "dragging" : ""
                      }`}
                    >
                      {item.content}
                    </div>
                  )}
                </Draggable>
              ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
export default DragToReorderList;
