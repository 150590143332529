import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

  let defaultUrl = "https://spiemaps.go.yj.fr/";

  if (process.env.REACT_APP_ENVIRONMENT === "development") {
    defaultUrl = "/";
  } else if (process.env.REACT_APP_ENVIRONMENT === "localhostDev") {
    defaultUrl = "http://192.168.0.23:8181/";
  } else if (process.env.REACT_APP_ENVIRONMENT === "localhost") {
    defaultUrl = "http://localhost:8181/";
  }



export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${defaultUrl}api/`,
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    mode: "cors",
  }),
  endpoints: (builder) => ({
    getUserDetails: builder.query({
      query: () => ({
        url: "user/profile",
        method: "GET",
      }),
    }),
    getAllUsers: builder.query({
      query: ({ page = 1, limit = 10 }) => ({
        url: `user/getAllUsers?page=${page}&limit=${limit}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetUserDetailsQuery, useGetAllUsersQuery } = authApi;
