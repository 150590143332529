import secureLocalStorage from "react-secure-storage";
import react, { useContext } from "react";
import { toast } from "react-toastify";


export async function makeRequest(
  path,
  method = "GET",
  headers = {},
  body = null,
  options = { mode: "cors" },
  onUploadProgress = null
) {
  let toastOptions = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  let defaultHeaders = { 
  };



  if (body) {
    if (typeof body === "object" && !(body instanceof FormData)) {
      defaultHeaders["Content-Type"] = "application/json";
    }
  } else {
    defaultHeaders["Content-Type"] = "application/json";
  }
  const mergedHeaders = {
    ...defaultHeaders,
    ...headers,
  };
const backendURL = process.env.REACT_APP_BACK_URL;
  let defaultUrl = backendURL;

  if (process.env.REACT_APP_ENVIRONMENT === "development") {
    defaultUrl = "/";
  } else if (process.env.REACT_APP_ENVIRONMENT === "localhostDev") {
    defaultUrl = "http://192.168.0.23:8181/";
  } else if (process.env.REACT_APP_ENVIRONMENT === "localhost") {
    defaultUrl = "http://localhost:8181/";
  }

  const url = defaultUrl + path;


  if (body && body instanceof FormData) {
    const xhr = new XMLHttpRequest();
    xhr.open(method.toUpperCase(), url, true);

    Object.keys(mergedHeaders).forEach((key) => {
      xhr.setRequestHeader(key, mergedHeaders[key]);
    });

    xhr.withCredentials = true;

    if (onUploadProgress) {
      xhr.upload.addEventListener("progress", onUploadProgress);
    }

    xhr.send(body);

    return new Promise((resolve, reject) => {
      xhr.onload = function () {
        if (xhr.status >= 200 && xhr.status < 300) {
          const contentType = xhr.getResponseHeader("content-type");
          const response =
            contentType && contentType?.includes("application/json")
              ? JSON.parse(xhr.responseText)
              : xhr.responseText;
          resolve(response);
        } else {
          switch (xhr.status) {
            case 400:
              toast.error(
                method === "GET"
                  ? "La demande est incorrecte. Impossible de récupérer les données."
                  : "La demande est incorrecte. Veuillez vérifier vos données et réessayer.",
                toastOptions
              );
              break;
            case 401:
              toast.error(
                method === "GET"
                  ? "Vous n'êtes pas autorisé à accéder à ces données. Veuillez vous connecter et réessayer."
                  : "Vous n'êtes pas autorisé à effectuer cette action. Veuillez vous connecter et réessayer.",
                toastOptions
              );
              break;
            case 403:
              toast.error(
                method === "GET"
                  ? "Vous n'avez pas la permission d'accéder à ces données."
                  : "Vous n'avez pas la permission d'effectuer cette action.",
                toastOptions
              );
              break;
            case 404:
              toast.error(
                method === "GET"
                  ? "Les données demandées n'ont pas été trouvées."
                  : "La ressource demandée n'a pas été trouvée.",
                toastOptions
              );
              break;
            case 409:
              toast.error(
                method === "GET"
                  ? "Un conflit s'est produit lors de la récupération des données."
                  : "Un conflit s'est produit lors de l'envoi de vos données. Il se peut que les informations que vous essayez de soumettre existent déjà.",
                toastOptions
              );
              break;
            case 500:
              console.error(xhr.status, xhr.responseText);
              toast.error(
                "Une erreur interne du serveur s'est produite. Veuillez réessayer plus tard.",
                toastOptions
              );
              break;

            default:
              toast.error(
                "Une erreur s'est produite ! Merci de ressayer dans quelques instants ou de contacter un administrateur.",
                toastOptions
              );
          }
          reject(new Error(`Request failed with status ${xhr.status}`));
        }
      };

      xhr.onerror = function () {
        toast.error(
          "Une erreur s'est produite ! merci de ressayer dans quelques instants ou de contacter un Administrateur",
          toastOptions
        );
        reject(new Error("Request failed"));
      };
    });
  } else {
    const requestOptions = {
      method: method.toUpperCase(),
      headers: new Headers(mergedHeaders),
       credentials: "include",
       ...options,
    };

    if (body) {
      requestOptions.body =
        typeof body === "object" ? JSON.stringify(body) : body;
    }
    try {
      const response = await fetch(url, requestOptions);
      console.log(response);
      const contentType = response.headers.get("content-type");
      if (response.ok) {
        if (contentType && contentType?.includes("application/json")) {
          return await response.json();
        } else {
          return await response.text();
        }
      } else if (response.status === 403 || response.status === 401) {
        const data = await response.json();
        let message = data.error;
        const toastOptionsWithRedirect = {
          ...toastOptions,
          onClose: () => {
           // Cookies.remove("jwt")
            secureLocalStorage.removeItem("user");
            if (window.location.pathname !== "/login")
              window.location.replace("/");
          },
        };

      
          const specificErrors = [
            { error: "refresh_token" },
            { error: "Bad xsrf token" },
            { error: "Missing XSRF token in headers" },
            { error: "Missing token in cookie" },
            { error: "Refresh token is not valid !" },
          ];

          const matchedError = specificErrors.find((specificError) =>
            data?.error?.includes(specificError.error)
          );
          if (matchedError) {
            message = "An error occured please reconnect :(";
          }

          toast.error(message, toastOptionsWithRedirect);
          return null;
        
      } else if (response.status === 404) {
        const data = await response.json();
        const message = data.error;
        toast.info(message, toastOptions);
        return null;
      } else if (response.status === 400) {
        const data = await response.json();
        const message = data.error;
        toast.error(message, toastOptions);
        return null;
      } else {
        throw new Error(`Request failed with status ${response.status}`);
      }
    } catch (e) {
      console.log("error", e);
      toast.error(
        "Une erreur s'est produite ! merci de ressayer dans quelques instants ou de contacter un Administrateur",
        toastOptions
      );
      throw e;
    }
  }
}
