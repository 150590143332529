import { useEffect, useState } from "react";
import { makeRequest } from "../Utils";
import { TableInformations, TableRecap } from "../Components/attachement/table";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import jsPDF from "jspdf";
import { useNavigate, useLocation } from "react-router-dom";

let toastOptions = {
  position: "top-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};
const Attachement = () => {
  const { id } = useParams();
  const [tabsData, setTabsData] = useState([]);
  const [informations, setInformations] = useState({});
  const [comment, setComment] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const fetchData = async () => {
    try {
      const data = await makeRequest(`api/attachement/getByID?id=${id}`);
      if (!data) {
        toast.error("Attachement introuvable", toastOptions);
        navigate(location.state?.referrer || "/attachement");
        return;
      }

      let arrayData = JSON.parse(data?.value);

      const infoTab = arrayData.find((tab) => tab.label === "Informations");

      if (infoTab) {
        console.log("infoTab", infoTab.value);
        setInformations(infoTab.value);
      }

      const recapTab = arrayData.find((tab) => tab.label === "Récap");

      if (recapTab) {
        setComment(recapTab.value);
      }

      const filteredTabsData = arrayData.filter(
        (tab) => tab.label !== "Informations"
      );

      setTabsData(filteredTabsData);

      console.log(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const exportPDF = () => {
    // Récupérez les informations et récap

    const filteredTabsData = tabsData.filter(
      (tab) => tab.label !== "Récap" && tab.label !== "Informations"
    );

    // Créez une nouvelle instance de jsPDF
    const doc = new jsPDF();

    // Ajoutez les informations au document sous forme de tableau
    if (informations) {
      const keyMap = {
        appointment: "Date et horaires du rendez-vous",
        phone: "Téléphone",
        address: "Adresse",
        name: "Nom",
      };

      const infoArray = Object.entries(informations).map(([key, value]) => [
        keyMap[key] || key, // Utilisez la correspondance de clés si elle existe, sinon utilisez la clé originale
        value,
      ]);

      doc.autoTable({
        startY: 10,
        body: infoArray,
      });
    }

    // Définissez les en-têtes de la table
    const headers = ["No Article", "Content", "Pts", "Quantite", "Total"];

    // Préparez les données pour la table
    const data = [];

    let totalPts = 0;
    // Parcourez chaque onglet de tabsData
    let lastKey = null;
    filteredTabsData.forEach((tab, i) => {
      Object.entries(tab.value).forEach(([key, array]) => {
        if (array.some((item) => item.quantite > 0)) {
          // Si la clé a changé, ajoutez une nouvelle ligne avec la clé en colspan 5
          if (key !== lastKey) {
            data.push([
              {
                content: key,
                colSpan: 5,
                styles: {
                  halign: "center",
                  fillColor: [169, 169, 169],
                  textColor: [0, 0, 0],
                },
              },
              {},
              {},
              {},
              {},
            ]);
            lastKey = key;
          }
          array.forEach((item) => {
            if (item.quantite > 0) {
              data.push([
                item.noArticle,
                item.content,
                item.pts,
                item.quantite,
                item.pts * item.quantite,
              ]);
              totalPts += item.pts * item.quantite;
            }
          });
        }
      });
    });

    // Ajoutez la table au document
    doc.autoTable({
      startY: 60, // ajustez cette valeur en fonction de la hauteur de votre tableau d'informations
      head: [headers],
      body: data,
      theme: "grid",
    });

    // Après avoir dessiné toutes les tables, ajoutez le total
    doc.autoTable({
      startY: doc.previousAutoTable.finalY,
      body: [
        [
          {
            content: "total",
            colSpan: 4,
            styles: { halign: "center" },
          },
          {
            content: totalPts,
            colSpan: 1,

            styles: { halign: "right" },
          },
        ],
      ],
      theme: "grid",
      styles: { textColor: 255, fillColor: [26, 188, 156] },
      columnStyles: {
        0: { cellWidth: "auto" }, // 'total' prendra toute la place restante
        1: { cellWidth: "wrap" }, // 'totalPts' prendra une taille maximale du texte
      },
    });

    // Ajoutez le récapitulatif à la fin du document
    if (comment) {
      const finalY = doc.previousAutoTable.finalY; // récupère la position y de la fin de la dernière table

      doc.setFont("helvetica", "bold");
      doc.text(`Commentaire: `, 10, finalY + 10); // ajustez le +10 en fonction de l'espace que vous voulez laisser après la table

      doc.setFont("helvetica"); // pour le texte normal, ne définissez pas le style de la police
      doc.setFontSize(10); // définir la taille de la police à 10

      const maxWidth = 150; // définir la largeur maximale du texte
      const textLines = doc.splitTextToSize(`${comment}`, maxWidth); // diviser le texte en plusieurs lignes

      doc.text(textLines, 50, finalY + 10); // ajustez le 60 pour aligner ce texte avec "Commentaire:"
    }

    let fileName = "attachement.pdf";

    if (informations && informations.reference) {
      fileName = "attachement_" + informations.reference + ".pdf";
    }
    // Enregistrez le document
    doc.save(fileName);
  };

  const handleQuantityChange = (item, index, key, newQuantity) => {
    setTabsData((prevData) => {
      const tabIndex = prevData.findIndex((tab) => tab.label === key);
      if (tabIndex !== -1) {
        const newData = [...prevData];
        Object.values(newData[tabIndex].value).forEach((array) => {
          const itemIndex = array.findIndex(
            (i) => i.noArticle === item.noArticle
          );
          if (itemIndex !== -1) {
            array[itemIndex].quantite = newQuantity;
          }
        });
        return newData;
      }
      return prevData;
    });
  };

  const handleChange = (e) => {
    setInformations({
      ...informations,
      [e.target.name]: e.target.value,
    });
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };
  function areInformationsComplete(informations) {
    const requiredProperties = ["reference", "name", "address", "commune"];

    for (let property of requiredProperties) {
      if (
        !informations.hasOwnProperty(property) ||
        informations[property] === ""
      ) {
        return false;
      }
    }

    return true;
  }

  const handleSave = async (status) => {
    const infoTab = tabsData.find((tab) => tab.label === "Informations");
    if (infoTab) {
      infoTab.value = informations; // ou toute autre valeur que vous souhaitez assigner
    } else {
      // Si l'onglet "Informations" n'existe pas, ajoutez-le
      tabsData.unshift({
        label: "Informations",
        value: informations, // ou toute autre valeur que vous souhaitez assigner
      });
    }

    const filteredTabsData = tabsData.filter(
      (tab) => tab.label !== "Récap" //TODO: remove  tab.label !== "Informations" &&
    );

    if (comment && comment.trim() !== "") {
      filteredTabsData.push({ label: "Récap", value: comment });
    }

    try {
      if (id) {
        const formData = new FormData();
        formData.append("attachement", JSON.stringify(filteredTabsData));
        formData.append("status", status);
        formData.append("id", id);

        const data = await makeRequest(
          "api/attachement/update",
          "PUT",
          null,
          formData
        );

        toast.success(
          "Les données ont été enregistrées avec succès",
          toastOptions
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    tabsData.length !== 0 && (
      <div className="w-full p-8">
        {informations && (
          <div className="mb-32">
            <p>Informations relatif à l'attachement </p>
            <TableInformations
              informations={informations}
              handleChange={handleChange}
            />
          </div>
        )}
        <table className="table-auto table w-full text-center border-collapse">
          <thead>
            <tr>
              <th className="border w-1/6">No Article</th>
              <th className="border w-2/6">Désignation</th>
              <th className="border w-1/6">Pts</th>
              <th className="border w-1/6">Quantité</th>
              <th className="border w-1/6">Total</th>
            </tr>
          </thead>
          <TableRecap
            tabsData={tabsData}
            editMode={true}
            handleQuantityChange={handleQuantityChange}
            handleComment={handleCommentChange}
            handleSave={handleSave}
            HandleDownloadToPdf={exportPDF}
            informationsCompleted={areInformationsComplete(informations)}
          />
        </table>
      </div>
    )
  );
};

export default Attachement;
