import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "../features/auth/authActions";
import { useNavigate } from "react-router-dom";
const RegisterScreen = () => {
  const { loading, userInfo, error, success } = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();
    const navigate = useNavigate();

  const submitForm = (data) => {
    // check if passwords match
    if (data.password !== data.confirmPassword) {
      alert("Password mismatch");
    }
    // transform email string to lowercase to avoid case sensitivity issues in login
    data.email = data.email.toLowerCase();
    dispatch(registerUser(data));
  };

  useEffect(() => { console.log('error : ',error) }, [error]);
   useEffect(() => {
     if (success) {
       navigate("/login");
     }
   }, [success, navigate]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Créer un compte
          </h2>
        </div>
        {error && <div className="text-center text-red-500">{error}</div>}
        <form className="mt-8 space-y-6" onSubmit={handleSubmit(submitForm)}>
          <div className="rounded-md shadow-sm flex flex-col space-y-4">
            <div>
              <label htmlFor="Prenom" className="sr-only">
                Prénom
              </label>
              <input
                type="text"
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Prénom"
                {...register("prenom")}
                required
              />
            </div>
            <div>
              <label htmlFor="Nom" className="sr-only">
                Nom
              </label>
              <input
                type="text"
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Nom"
                {...register("nom")}
                required
              />
            </div>
            <div>
              <label htmlFor="email" className="sr-only">
                Email
              </label>
              <input
                type="email"
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Email"
                {...register("email")}
                required
              />
            </div>
            <div>
              <label htmlFor="email" className="sr-only">
                Site
              </label>
              <select
                required
                className=" w-full relative block rounded-t-md px-3 py-2 border border-gray-300 focus:outline-none   "
                {...register("site")}
                placeholder="Site"
              >
                <option disabled selected>
                  Veuillez séléctionner votre site
                </option>
                <option value="Sainte-Marguerite-de-Viette">
                  Sainte-Marguerite-de-Viette
                </option>
                <option value="Mouen">Mouen</option>
              </select>
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                type="password"
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Password"
                {...register("password")}
                required
              />
            </div>
            <div>
              <label htmlFor="confirmPassword" className="sr-only">
                Confirmation du mot de passe
              </label>
              <input
                type="password"
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Confirmation du mot de passe"
                {...register("confirmPassword")}
                required
              />
            </div>
          </div>

          <div className="w-full  flex justify-center items-center">
            <button
              type="submit"
              className="group relative w-52 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              disabled={loading}
            >
              Crée mon compte
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default RegisterScreen;
