import React, { useState, useContext, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";

import { userLogout } from "../features/auth/authActions";
import { CircularProgress } from "../Components/CircularProgress";
import { makeRequest } from "../Utils";

const UserPage = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const emailRef = useRef();
  const newPasswordRef = useRef();
  const prenomRef = useRef();
  const nomRef = useRef();
  const siteRef = useRef();
  const [activeTab, setActiveTab] = useState("profile");
  const [editable, setEditable] = useState(false);
  const [loading, setLoading] = useState(true);

  const toggleEditable = () => {
    setEditable(!editable);
  };

  const handleSubmit = async () => {
    const email = emailRef.current.value;
    const prenom = prenomRef.current.value;
    const nom = nomRef.current.value;
    const site = siteRef.current.value;
    const password = newPasswordRef.current.value;

    // const result = null;
    console.log("Updating user...", email, prenom, nom, password);

    let formData = new FormData();
    formData.append("id", userInfo.id);
    formData.append("prenom", prenom);
    formData.append("nom", nom);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("site", site);

    const result = await makeRequest("api/user/update", "PUT", null, formData);
    console.log("Updating user...", result);

    // await updateUser(
    //   user.currentUser.id,
    //   pseudo,
    //   password,
    //   email,
    //   role
    // );
    if (result?.msg == "Utilisateur mis à jour avec succès.") {
      toast.success("Vos données ont bien été mise à jour", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setTimeout(() => {
        window.location.href = "/profile";
      }, 500);
    } else {
      console.log("Résultat de la mise à jour :", result);
    }
  };

  const handleDeleteAccount = async () => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer votre compte ?")) {
      const result = null; //deleteUserById(user?.currentUser?.id);
      if (result === null) {
      } else {
        toast.success("Votre opération a été effectuée avec succès !", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        userLogout();
        setTimeout(() => {
          window.location.href = "/register";
        }, 500);
      }
      console.log("Deleting account...");
    }
  };

  useEffect(() => {
    if (userInfo) setLoading(false);
  }, [userInfo]);

  return (
    <>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <CircularProgress />
        </div>
      ) : (
        <div className="flex flex-col justify-center min-h-screen bg-gray-100">
          <div className="bg-white shadow-lg rounded-lg px-8 pt-6 pb-8 mb-4 mx-auto w-3/4 min-h-[500px]">
            <div className="flex flex-row items-center mb-4">
              <p className="text-2xl font-bold">Votre compte :</p>
              <div className="pr-1" />
              <p className="text-2xl">{userInfo.pseudo}</p>
            </div>

            {activeTab === "profile" && (
              <div>
                {editable ? (
                  <>
                    <div className="mb-4">
                      <label
                        htmlFor="prenom"
                        className="block text-black font-bold mb-2"
                      >
                        Prénom :
                      </label>
                      <input
                        type="text"
                        id="prenom"
                        defaultValue={userInfo.prenom}
                        ref={prenomRef}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
                      />
                    </div>
                    <div className="mb-4">
                      <label
                        htmlFor="pseudo"
                        className="block text-black font-bold mb-2"
                      >
                        Nom :
                      </label>
                      <input
                        type="text"
                        id="pseudo"
                        defaultValue={userInfo.nom}
                        ref={nomRef}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
                      />
                    </div>
                    <div className="mb-4">
                      <label
                        htmlFor="email"
                        className="block text-black font-bold mb-2"
                      >
                        Email :
                      </label>
                      <input
                        type="email"
                        id="email"
                        defaultValue={userInfo.email}
                        ref={emailRef}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
                      />
                    </div>
                    <div className="mb-4">
                      <label
                        htmlFor="email"
                        className="block text-black font-bold mb-2"
                      >
                        Site :
                      </label>
                      <select
                        ref={siteRef}
                        required
                        className="shadow  border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
                        defaultValue={userInfo.site}
                      >
                        <option disabled selected value="">
                          Sélectionner un site
                        </option>
                        <option value="Sainte-Marguerite-de-Viette">
                          Sainte-Marguerite-de-Viette
                        </option>
                        <option value="Mouen">Mouen</option>
                      </select>
                    </div>
                    <div className="mb-4">
                      <label
                        htmlFor="password"
                        className="block text-black font-bold mb-2"
                      >
                        Mot de passe :
                      </label>
                      <input
                        type="password"
                        id="newPassword"
                        placeholder="Nouveau mot de passe "
                        ref={newPasswordRef}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
                      />
                    </div>
                    <div className="flex justify-between items-center mb-6">
                      <div className="space-x-4">
                        <button
                          onClick={handleSubmit}
                          id="saveUser"
                          className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        >
                          Sauvegarder les modifications
                        </button>
                        <button
                          onClick={toggleEditable}
                          id="saveUser"
                          className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        >
                          Annuler les modifications
                        </button>
                      </div>
                      {/* <button
                        id="deleteAccount"
                        onClick={handleDeleteAccount}
                        className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                      >
                        Supprimer mon compte
                      </button> */}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="mb-4">
                      <label
                        htmlFor="prenom"
                        className="block text-black font-bold mb-2"
                      >
                        Prenom :
                      </label>
                      <input
                        type="text"
                        id="prenom"
                        readOnly
                        value={userInfo.prenom}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
                      />
                    </div>
                    <div className="mb-4">
                      <label
                        htmlFor="nom"
                        className="block text-black font-bold mb-2"
                      >
                        Nom :
                      </label>
                      <input
                        type="text"
                        id="nom"
                        readOnly
                        value={userInfo.nom}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
                      />
                    </div>
                    <div className="mb-4">
                      <label
                        htmlFor="email"
                        className="block text-black font-bold mb-2"
                      >
                        Email :
                      </label>
                      <input
                        type="email"
                        id="email"
                        readOnly
                        value={userInfo.email}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
                      />
                    </div>
                    <div className="mb-4">
                      <label
                        htmlFor="site"
                        className="block text-black font-bold mb-2"
                      >
                        Site :
                      </label>
                      <input
                        type="text"
                        id="site"
                        readOnly
                        value={userInfo.site}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
                      />
                    </div>
                    <button
                      id="editAccount"
                      onClick={toggleEditable}
                      className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mb-4"
                    >
                      Modifier le profile
                    </button>
                    {/* <div className="flex justify-end">
                      <button
                        id="deleteAccount"
                        onClick={handleDeleteAccount}
                        className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                      >
                        Supprimer le compte
                      </button>
                    </div> */}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default UserPage;
