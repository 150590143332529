const PartnerList = [
    {
        id: 1,
        name: 'loxam',
    },
    {
        id: 2,
        name: 'axel location',
    },
    {
        id: 3,
        name: 'kiloutou',
    },
    {
        id: 4,
        name: 'salti',
    },
    {
        id: 5,
        name: 'regis location',
    },
    {
        id: 6,
        name: 'bleu blanc',
    },
    {
        id: 7,
        name: "m-loc",
    },
    {
        id: 8,
        name: "termaloc",
    },
    {
        id: 9,
        name: "loccamat"
    }
];
export default PartnerList;