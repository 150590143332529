import { Fragment,useRef,useState } from 'react';
import { FaMapMarkerAlt, FaPhoneSquare,FaPhone } from 'react-icons/fa';
import { AiOutlineClockCircle,AiOutlinePhone, AiFillPhone, AiFillApple, AiTwotonePhone } from 'react-icons/ai';
import { MdStar } from 'react-icons/md';
import { GrClose } from 'react-icons/gr';
 
 function Partner(props) {

     const openHours = useRef([]);
     const contactPhone = useRef([])

     if (props.marker.openingHours) {
         if (props.marker.openingHours.length > 1) {
             openHours.current = props.marker.openingHours[1]                                                                                                                                                                                                                                                                                                                 
    }
         else if (props.marker.openingHours.length > 0)
             openHours.current = props.marker.openingHours[0]
     }

     if (props.marker.contacts)
     {
        if (props.marker.contacts[0].phone)
         if (props.marker.contacts[0].phone.length > 1)
         {
            contactPhone.current = props.marker.contacts[0].phone[1]           
         }
         else
            contactPhone.current = props.marker.contacts[0].phone[0]
  
     }


     const handleOnClick = () => {
         props.onClick(props.title);
     };



     return (
         <div className="h-1/4 border-b" onClick={handleOnClick}>
             <div className="flex items-center justify-center mb-4">
                 <h2 className="text-xl font-bold">{props.title}</h2>
             </div>
             <div className="flex items-center mb-4">
                 <FaMapMarkerAlt size={24} className="text-red-500 mr-2" />
                 <p>{props.address.label}</p>
             </div>
             <>
              
                 {openHours.current.length !=0 ? (
                     <div className="flex items-center mb-4">
                         <AiOutlineClockCircle size={24} className={openHours.current.isOpen ? "text-green-500 mr-2" :  "text-red-500 mr-2"  } />
                        
                         <ul>
                             
                             {openHours.current.text.filter(result => result !== null && result !== undefined).map((time, i) => (
                                 <li key={i}>{time}</li>
                             ))}
                         </ul>
                     </div>
                 ) :("") }  

                   {contactPhone.current.length !=0 ? (
                     <div className="flex items-center mb-4">
                         <AiOutlinePhone size={24} style={{ transform: 'rotate(90deg)' }} className="text-blue-500 mr-2"  />
                        
                         <ul>
                             <p><a href={"tel:"+contactPhone.current.value}>{contactPhone.current.value}</a></p>
                         </ul>
                     </div>
                 ) :("") }  
             </>
             
         </div>
     );
}


export default function PartnersList(props) {
    return (
        <div className=''>

            {props.markerlist.length > 0 ? (props.markerlist.map((marker, index) => (
                <Partner key={index} marker={marker} title={marker.title} address={marker.address} onClick={props.onClick} />
            ))) :("")}
        </div>
    );
}