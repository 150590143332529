import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { updatePassword } from "../features/auth/authActions"; // Assurez-vous de créer cette action
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const toastOptions = {
  position: "top-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

const UpdatePasswordScreen = () => {
  const token = useParams().token;
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();

  const { loading, userInfo, error, success } = useSelector(
    (state) => state.auth
  );

  console.log('success', success)

  const submitForm = (data) => {
    // check if passwords match
    if (data.newPassword !== data.confirmNewPassword) {
      toast.error("Les mots de passe ne correspondent pas", toastOptions);
      return;
    }
    console.log("tokenUpdate", token);
    dispatch(updatePassword({password :data.newPassword, token : token}));
  };
  useEffect(() => {
    if (!token) {
      toast.error("Token invalide", toastOptions);
      navigate("/login");
      return;
    }
  }, [token]);
  useEffect(() => {
    console.log("error : ", error);
  }, [error]);

  useEffect(() => {
    if (success) {
     // toast.success("Mot de passe mis à jour avec succès", toastOptions);
      navigate("/login");
    }
  }, [success]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Modifier le mot de passe
          </h2>
        </div>
        {error && <div className="text-center text-red-500">{error}</div>}
        <form className="mt-8 space-y-6" onSubmit={handleSubmit(submitForm)}>
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="newPassword" className="sr-only">
                Nouveau mot de passe
              </label>
              <input
                type="password"
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Nouveau mot de passe"
                {...register("newPassword")}
                required
              />
            </div>
            <div>
              <label htmlFor="confirmNewPassword" className="sr-only">
                Confirmation du nouveau mot de passe
              </label>
              <input
                type="password"
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Confirmation du nouveau mot de passe"
                {...register("confirmNewPassword")}
                required
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              disabled={loading}
            >
              Modifier le mot de passe
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdatePasswordScreen;
