import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userLogout } from "../../features/auth/authActions";

const AdminNavigation = () => {

  const [window, setWindow] = useState(false);
  const { userInfo } = useSelector((state) => state.auth);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const location = useLocation();
  let openClose = () => {
    setWindow(!window);
  };
  const dispatch = useDispatch();


  const li = [
    ["Accueil", "/attachement", "/img/logo.png"],
    ["Dashboard", "/admin", "/img/dashboard.svg"],
    ["Utilisateurs", "/admin/users", "/img/manage user.svg"],
    ["Attachements", "/admin/userAttachements", "/img/manage  order.svg"],
    [
      "Configuration",
      "/admin/orders",
      "/img/configuration.svg",
      [["Attachements", "/admin/attachment/config", "/img/manage  order.svg"]],
      "admin",
    ],
    ["Se deconnecter", "/attachement", "/img/logout.svg"],
  ];

  return (
    <nav
      className={`h-screen bg-white relative shadow transition-all duration-500 ease-in-out ${
        window ? "w-21" : "w-64"
      }`}
    >
      <div className="flex justify-end">
        <img
          className={`z-10 mt-2 cursor-pointer absolute mr-[-15px] bg-white ${
            window
              ? "rotate-180 transition-all duration-500"
              : "transition-all duration-500"
          }`}
          src="/img/left-arrow.svg"
          onClick={openClose}
        />
      </div>
      <ul className="list-none mt-2">
        {li.map((item, i) => (
          <div key={i}>
            {Array.isArray(item[3]) ? (
              item[4] === userInfo?.role && (
                <div className="flex flex-col justify-start items-start px-0 border-b border-t border-gray-600 w-full h-full p-2">
                  <button
                    onClick={(event) => {
                      event.preventDefault();
                      setDropdownOpen(!dropdownOpen);
                    }}
                    className="focus:outline-none focus:text-indigo-400 text-center text-black flex justify-between items-center px-4 h-10 "
                  >
                    <img className={`pl-1`} src={item[2]} alt={item[0]} />
                    <li
                      className={` inline-block pl-3 text-sm font-light leading-9  ${
                        window ? "hidden" : "visible"
                      }`}
                    >
                      {item[0]}
                    </li>

                    <img
                      className={` ${window ? "pl-1" : "visible"}`}
                      src={
                        dropdownOpen
                          ? "/img/arrow-up.svg"
                          : "/img/arrow-down.svg"
                      }
                      alt="arrow"
                    />
                  </button>
                  {dropdownOpen &&
                    item[3].map((subItem, j) => (
                      <Link to={subItem[1]} key={j}>
                        <div
                          className={`relative flex items-center px-4 h-10  ml-2 mt-2 ${
                            i === 0 ? "mt-12" : ""
                          } ${
                            location.pathname === subItem[1]
                              ? "border-l-4 border-blue-400 "
                              : ""
                          } cursor-pointer group`}
                        >
                          <img
                            className={`pl-${window ? "1" : "1"}`}
                            src={subItem[2] ? subItem[2] : item[2]}
                            alt={subItem[0]}
                          />
                          <li
                            className={`inline-block pl-3 text-sm font-light leading-9 truncate ${
                              window ? "hidden" : "visible"
                            }`}
                          >
                            {subItem[0]}
                          </li>
                          {window && (
                            <div
                              className={`
                absolute left-full rounded-md px-2 py-1 ml-6 
                bg-indigo-100 text-indigo-800 text-sm
                invisible opacity-0 -translate-x-3 transition-all
                group-hover:visible group-hover:opacity-100 group-hover:translate-x-0
              `}
                            >
                              {subItem[0]}
                            </div>
                          )}
                        </div>
                      </Link>
                    ))}
                </div>
              )
            ) : (
              <Link
                to={item[1]}
                target="_self"
                onClick={async (event) => {
                  if (
                    item[1] === "/attachement" &&
                    item[0] === "Se deconnecter"
                  ) {
                    dispatch(userLogout());
                  }
                }}
              >
                <div
                  className={`relative flex items-center px-4 h-10 mt-4 ${
                    i === 0 ? "mt-12" : "mt-1"
                  } ${
                    location.pathname === item[1]
                      ? "border-l-4 border-blue-400"
                      : ""
                  } cursor-pointer group`}
                >
                  <img
                    className={`pl-${window ? "1" : "1"} h-7`}
                    src={item[2]}
                    alt={item[0]}
                  />
                  <li
                    className={`inline-block pl-3 text-sm font-light leading-9 ${
                      window ? "hidden" : "visible"
                    }  `}
                  >
                    {item[0]}
                  </li>
                  {window && (
                    <div
                      className={`
                absolute left-full rounded-md px-2 py-1 ml-6
                bg-indigo-100 text-indigo-800 text-sm
                invisible opacity-0 -translate-x-3 transition-all
                group-hover:visible group-hover:opacity-100 group-hover:translate-x-0
              `}
                    >
                      {item[0]}
                    </div>
                  )}
                </div>
              </Link>
            )}
          </div>
        ))}
      </ul>
      {/* <div className="absolute bottom-0 mb-4 ml-4">{userInfo.prenom}</div> */}
    </nav>
  );
};

export default AdminNavigation;
