import { useEffect } from "react";
import { Route, Navigate, Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCredentials } from "../features/auth/authSlice";
import { useGetUserDetailsQuery } from "../features/auth/authService";
import { CircularProgress } from "../Components/CircularProgress";
import { useLocation } from "react-router-dom";

export default function AdminRoute({ children, ...props }) {
  const location = useLocation();
  const { loading, userInfo } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data, isFetching, error, refetch } =
    useGetUserDetailsQuery("userDetails");

  useEffect(() => {
    if (!isFetching) {
      if (data && !error) {
        dispatch(setCredentials(data));
      }

      if (error) {
        navigate("/attachement");
      }
    }
  }, [data, error, location, userInfo, isFetching, dispatch, navigate]);

  useEffect(() => {
    if (!isFetching && data) {
      if (data.role !== "admin" && data.role !== "gestionnaire") {
        navigate("/attachement");
      }
    }
  }, [data, isFetching, navigate]);

  if (isFetching && !data) {
    return (
      <div className="fixed top-0 left-0 z-50 w-screen h-screen flex items-center justify-center bg-black bg-opacity-50">
        <CircularProgress />
      </div>
    );
  }

  return <Outlet />;
}
