import React, { useState, useEffect } from "react";
import ModalAlert from "../../Components/ModalAlert";
import {
  TrashIcon,
  PencilIcon,
  ArrowPathIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  UserPlusIcon,
} from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import { useGetAllUsersQuery } from "../../features/auth/authService";
import { CircularProgress } from "../../Components/CircularProgress";
import { deleteUser, registerUser } from "../../features/auth/authActions";
import { makeRequest } from "../../Utils";
import { toast } from "react-toastify";

let toastOptions = {
  position: "top-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

function UsersPage() {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.auth);
  const [users, setUsers] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [form, setForm] = useState({
    prenom: "",
    nom: "",
    email: "",
    role: "User",
    site: "",
    password: "",
  });
  const [modalAlertOpen, setModalAlertOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const { data, isFetching, refetch } = useGetAllUsersQuery({
    page: currentPage,
    limit: itemsPerPage,
  });

  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    refetch();
  }, [currentPage, itemsPerPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    setUsers(data?.users);
    const result = Math.ceil((data ? data?.totalUsers : 1) / itemsPerPage);
    setTotalPages(result);
  }, [data]);

  const generatePassword = () => {
    const length = 12;
    const charset =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let password = "";
    for (let i = 0; i < length; i++) {
      const at = Math.floor(Math.random() * charset.length);
      password += charset[at];
    }
    return password;
  };

  const handleAdd = () => {
    setForm({ prenom: "", nom: "", email: "", role: "",site:"", password: "" });
    setSelectedUser(null);
    setModalOpen(true);
  };

  const handleEdit = (user) => {
    setForm(user);
    setSelectedUser(user);
    setModalOpen(true);
  };
  const openModalAlert = (user) => {
    setUserToDelete(user);
    setModalAlertOpen(true);
  };
  const closeModalAlert = () => {
    setModalAlertOpen(false);
  };

  const handleDelete = async () => {
    if (userToDelete.id)
       dispatch(deleteUser(userToDelete.id)).then(() => refetch());
    if (currentPage === totalPages && users.length === 1) {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
      }
    }
   
    closeModalAlert();
  };

  const handleSave = async () => {
    if (selectedUser) {
      let formData = new FormData();

      for (let key in form) {
        formData.append(key, form[key]);
      }

      formData.append("id", selectedUser.id);
      try {
        setSelectedUser(null);
        const data = await makeRequest(
          "api/user/update",
          "PUT",
          null,
          formData
        );

        toast.success("Utilisateur modifié avec succès", toastOptions);
         refetch();
      } catch (error) {
        
        toast.error(error, toastOptions);
        console.error(error);
      }
    } else {
       dispatch(registerUser({ ...form, otherUser: true })).then(() =>
         refetch()
       );
    }

    setModalOpen(false);
  };

  const handleGeneratePassword = () => {
    setForm({ ...form, password: generatePassword() });
  };

  return (
    <div className=" w-full p-8 ">
      <table className="table-auto table w-full select-none">
        <thead>
          <tr>
            <th className="px-4 py-2 w-[10%] text-left">
              <UserPlusIcon
                onClick={handleAdd}
                className="h-6 w-6 cursor-pointer"
              />
            </th>
            <th className="px-4 py-2 w-1/4 text-left">nom</th>
            <th className="px-4 py-2 w-1/4 text-left">prenom</th>
            <th className="px-4 py-2 w-1/4 text-left">Email</th>
            <th className="px-4 py-2 w-1/4 text-left">Site</th>
            <th className="px-4 py-2 w-1/4 text-left">Role</th>
          </tr>
        </thead>
        <tbody>
          {isFetching ? (
            <tr className="h-full w-full">
              <td colSpan={5} className="h-full w-full">
                <div className=" mt-20 flex text-center items-center justify-center">
                  <CircularProgress />
                </div>
              </td>
            </tr>
          ) : (
            users?.map((user) => (
              <tr key={user.id} className="border-t border-gray-200">
                <td className="px-4 py-2 text-left flex items-center space-x-2">
                  <PencilIcon
                    className="h-6 w-6 text-gray-400 hover:text-black cursor-pointer"
                    onClick={() => handleEdit(user)}
                  />
                  <TrashIcon
                    className="h-6 w-6 text-red-200 hover:text-red-600 cursor-pointer"
                    onClick={() => openModalAlert(user)}
                  />
                </td>
                <td className="px-4 py-2 w-1/4 text-left">{user.nom}</td>
                <td className="px-4 py-2 w-1/4 text-left">{user.prenom}</td>
                <td className="px-4 py-2 w-1/4 text-left">{user.email}</td>
                <td className="px-4 py-2 w-1/4 text-left">{user.site}</td>
                <td className="px-4 py-2 w-1/4 text-left">{user.role}</td>
              </tr>
            ))
          )}
        </tbody>
      </table>
      {users && (
        <div className="mt-20 flex justify-center ">
          <ArrowLeftIcon
            className={`mt-3 h-6 w-6 cursor-pointer ${
              currentPage === 1 ? "text-gray-300" : "text-blue-500"
            }`}
            onClick={() => {
              if (currentPage > 1) {
                handlePageChange(currentPage - 1);
              }
            }}
          />
          <div className="flex-grow flex justify-center max-w-xs  space-x-1">
            {currentPage > 2 && (
              <button
                className="mx-1 px-3 py-2 bg-white border border-gray-300 rounded select-none"
                onClick={() => handlePageChange(1)}
              >
                1
              </button>
            )}
            {currentPage > 3 && (
              <button className="mx-1 px-3 py-2 bg-white border border-gray-300 rounded select-none">
                ...
              </button>
            )}
            {currentPage > 1 && (
              <button
                className="mx-1 px-3 py-2 bg-white border border-gray-300 rounded select-none"
                onClick={() => handlePageChange(currentPage - 1)}
              >
                {currentPage - 1}
              </button>
            )}
            <button
              className="mx-1 px-3 py-2 bg-blue-500 text-white rounded select-none"
              onClick={() => handlePageChange(currentPage)}
            >
              {currentPage}
            </button>
            {currentPage < totalPages && (
              <button
                className="mx-1 px-3 py-2 bg-white border border-gray-300 rounded select-none"
                onClick={() => handlePageChange(currentPage + 1)}
              >
                {currentPage + 1}
              </button>
            )}
            {currentPage < totalPages - 2 && (
              <button className="mx-1 px-3 py-2 bg-white border border-gray-300 rounded select-none">
                ...
              </button>
            )}
            {currentPage < totalPages - 1 && (
              <button
                className="mx-1 px-3 py-2 bg-white border border-gray-300 rounded select-none"
                onClick={() => handlePageChange(totalPages)}
              >
                {totalPages}
              </button>
            )}
          </div>
          <ArrowRightIcon
            className={`mt-3 h-6 w-6 cursor-pointer ${
              currentPage === totalPages ? "text-gray-300" : "text-blue-500"
            }`}
            onClick={() => {
              if (currentPage < totalPages) {
                handlePageChange(currentPage + 1);
              }
            }}
          />
        </div>
      )}
      {modalOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg p-8 max-w-md mx-auto">
            <h2 className="text-2xl font-bold mb-6 text-center">
              Détail de l'utilisateur
            </h2>
            <div className="flex flex-wrap -mx-2 mb-4">
              <label className="w-full md:w-1/2 px-2 mb-4 md:mb-0">
                Nom
                <input
                  required
                  className="w-full border rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  value={form.nom}
                  onChange={(e) => setForm({ ...form, nom: e.target.value })}
                />
              </label>
              <label className="w-full md:w-1/2 px-2 mb-4 md:mb-0">
                Prénom
                <input
                  required
                  className="w-full border rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  value={form.prenom}
                  onChange={(e) => setForm({ ...form, prenom: e.target.value })}
                />
              </label>
              <label className="w-full md:w-1/2 px-2">
                Email
                <input
                  type="email"
                  required
                  className="w-full border rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  value={form.email}
                  onChange={(e) => setForm({ ...form, email: e.target.value })}
                />
              </label>
              <label className="w-full md:w-1/2 px-2 mb-4 md:mb-0">
                Role
                <select
                  required
                  className="w-full border rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  value={form.role}
                  onChange={(e) => setForm({ ...form, role: e.target.value })}
                >
                  <option value="User">Utilisateur</option>
                  {userInfo.role !== "gestionnaire" && (
                    <option value="admin">Admin</option>
                  )}
                  <option value="gestionnaire">Gestionnaire</option>
                </select>
              </label>
              <label className="w-full md:w-1/2 px-2 mb-4 md:mb-0">
                Site
                <select
                  required
                  className="w-full border rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  value={form.site}
                  onChange={(e) => setForm({ ...form, site: e.target.value })}
                >
                  <option disabled selected value="">
                    Sélectionner un site
                  </option>
                  <option value="Sainte-Marguerite-de-Viette">
                    Sainte-Marguerite-de-Viette
                  </option>
                  <option value="Mouen">Mouen</option>
                </select>
              </label>
            </div>
            <div className="flex flex-wrap -mx-2 mb-4">
              <label className="w-full md:w-1/2 px-2">
                Password
                <div className="flex items-center">
                  <input
                    required
                    className="w-full border rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    value={form.password}
                    onChange={(e) =>
                      setForm({ ...form, password: e.target.value })
                    }
                  />
                  <ArrowPathIcon
                    className="h-6 w-6 text-blue-500 cursor-pointer ml-2"
                    onClick={handleGeneratePassword}
                  />
                </div>
              </label>
            </div>
            <div className="flex justify-end space-x-4 mt-6">
              <button
                disabled={
                  !form.nom ||
                  !form.prenom ||
                  !form.email ||
                  !form.site ||
                  (!form.password && !selectedUser)
                }
                className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${
                  !form.nom ||
                  !form.prenom ||
                  !form.email ||
                  !form.site ||
                  (!form.password && !selectedUser)
                    ? "opacity-50 cursor-not-allowed"
                    : ""
                }`}
                onClick={handleSave}
              >
                Save
              </button>
              <button
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                onClick={() => setModalOpen(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      <ModalAlert
        isOpen={modalAlertOpen}
        onClose={closeModalAlert}
        onConfirm={handleDelete}
      >
        <p>
          Etes-vous sûr de vouloir supprimer cet utilisateur : <br />{" "}
          {userToDelete?.nom} {userToDelete?.prenom}
        </p>
      </ModalAlert>
    </div>
  );
}

export default UsersPage;
